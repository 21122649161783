import { graphql } from "gatsby"

// import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"

// import { useIsAuthenticated } from "@azure/msal-react"

import SEO, { ImageProps, ArticleProps, HelmetProps } from "../components/Common/SEO"

import getBannerProps from "../components/Hero/getHeroBannerProps"
import getAuthor from "../components/Common/getAuthor"

import Search from "../components/Search"
const indexName = process.env.GATSBY_ALGOLIA_INDEX_NAME || "unknown"
const searchIndices = [{ name: indexName, title: indexName }]

/**
 *
 * @returns React Functional Component for search
 */
const Sok = ({ location, data }) => {
  const { contentfulGeneric: content } = data

  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content)

  const imageProps: ImageProps = {
    imageUrl: heroBannerProps.image.imageUrl,
    imageSecureUrl: heroBannerProps.image.imageSecureUrl,
    imageHeight: heroBannerProps.image.imageHeight,
    imageWitdh: heroBannerProps.image.imageWidth,
    imageAlt: heroBannerProps.image.imageAlt,
    imageFormat: heroBannerProps.image.imageFormat,
  }

  // The the author of the content
  const profile = getAuthor(content)

  const articleProps: ArticleProps = {
    publishTime: content.createdAt,
    modifiedTime: content.updatedAt,
    expirationTime: "",
    section: content.category,
    tags: content.tags,
    authors: [profile],
  }
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname
  const helmetProps: HelmetProps = {
    title: content.title || "Tittel mangler",
    slug: canonicalUrl,
    description: content.metadataDescription || "Les Gartnerhallens nyheter",
    contentType: "website",
    locale: content.node_locale || "nb_NO",
    isPublic: true,
    articleMetaTags: articleProps,
    images: [imageProps],
  }

  return (
    <>
      <SEO {...helmetProps} />
      <div className="relative">
        <Search indices={searchIndices} />
      </div>
    </>
  )
}
export const genericQuery = graphql`
  {
    contentfulGeneric(slug: { eq: "sok" }, node_locale: { eq: "nb-NO" }) {
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      body {
        childMdx {
          timeToRead
          body
        }
      }
      author {
        name
        firstName
        lastName
      }
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
            alt
          }
        }
        url
        secure_url
        width
        height
        format
      }
      node_locale
      slug
      title
      metadataDescription
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default Sok
