import { connectSearchBox } from "react-instantsearch-dom"
import { inputSearch } from "../Styling/Style"

export default connectSearchBox(({ refine, currentRefinement, onFocus }) => (
  <form autoComplete="On" name="Search">
    <div className="mt-8 mb-12 sm:mx-auto sm:w-full sm:max-w-md">
      <input
        id="searchBox"
        className={inputSearch + " px-4"}
        type="text"
        placeholder="Søk på artikkel, tema e.l"
        aria-label="Search"
        autoFocus
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault()
        }}
        onChange={(e) => {
          refine(e.target.value)
        }}
        value={currentRefinement}
        onFocus={onFocus}
      />
    </div>
  </form>
))
