import { GreenBadge } from "../Graphics/Badge"

const Menu = ({ items, refine }) => (
  <div className="hidden sm:block">
    <nav className="flex space-x-4" aria-label="Tabs">
      <a
        href="#"
        className="text-tomat-500 hover:text-orange-500 relative"
        onClick={(event) => {
          event.preventDefault()
          refine()
        }}
      >
        <GreenBadge title="Vis alle" />
      </a>

      {items.map((item) => (
        <a
          key={item.value}
          href="#"
          className={
            item.isRefined
              ? "inline-flex items-center px-3 py-1 rounded-full  text-button15  bg-bggronsaker-500  text-sort-200"
              : "border-transparent text-black-900 hover:text-tomat-500 rounded-full text-button15  hover:border-tomat-500 relative"
          }
          onClick={(event) => {
            event.preventDefault()
            refine(item.value)
          }}
        >
          <span className="align-middle">
            {item.label
              .replace("Contentful", "")
              .replace("Topics", "Tema")
              .replace("Article", "Aktuelt")
              .replace("ProductGroup", "Produktgrupper")
              .replace("ProductTeaser", "Produkter")
              .replace("Farmer", "Produsenter")}{" "}
            ({item.count})
          </span>
          <span aria-hidden="true" className="bg-transparent absolute inset-x-0 bottom-0 h-0.5"></span>
        </a>
      ))}
    </nav>
  </div>
)

export default Menu
