import { useEffect, useState } from "react"
import SearchLine from "./SearchResultLine"
import { Card } from "../Cards/Cards"
import getMainPath from "../Common/MainPaths"
import { getCloudinaryImage, CloudinaryImage } from "../Common/Cloudinary"
import useWindowSize from "../CustomHooks/useWindowSize"
import { useIsAuthenticated } from "@azure/msal-react"
import getBannerProps from "../Hero/getHeroBannerProps"
import HeroBannerProps from "../Hero/HeroTypes"
/**
 * @description Type used for parsing hits from Algoli
 */
type ParsedHitsType = {
  title: string
  ingress: string
  date: string
  category?: string
  pillText?: string
  id: string
  image: CloudinaryImage
  url: string
  heroBannerProps?: HeroBannerProps
}
const Hits = ({ hits }) => {
  // Used to set search to grid(cards) or lines. Smaller devices show cards by default
  const [grid, setGrid] = useState(false)
  const { width } = useWindowSize()
  useEffect(() => {
    if (width < 768) setGrid(true)
  }, [])
  // Create the search result list based on the hits that we get from Algolia
  const isAuthenticated = useIsAuthenticated()
  let parsedHits: Array<ParsedHitsType> = []
  parsedHits = hits
    .filter(function (hit) {
      // Not all entries are public. Hide those who are not, unless you are logged in.
      if (hit.public !== undefined && hit.public === false) if (!isAuthenticated) return false
      // if (hit.public === false) return false // search for MyPage-articles only not possible, even if logged in
      return true
    })
    .map((hit) => {
      const dtTimeUpdated = hit.updatedAt
      const dtTimeCreated = hit.createdAt
      const dtTimeCreatedModified = hit.createdAtModified
      let dt = ""
      if (dtTimeCreated !== undefined && dtTimeCreated !== undefined) {
        const dtArray = dtTimeCreated.split(" ")
        dt = dtArray[0]
      }
      if (dtTimeUpdated !== undefined && dtTimeUpdated !== null) {
        const dtArray = dtTimeUpdated.split(" ")
        dt = dtArray[0]
      }
      if (dtTimeCreatedModified !== undefined && dtTimeCreatedModified !== null) {
        const dtArray = dtTimeCreatedModified.split(" ")
        dt = dtArray[0]
      }
      let url = ""
      // Needed for line view of results.
      const heroBannerProps = getBannerProps(hit)
      // Pills are only added in grid view
      // TODO: Add add link in pill to category and use topic on topic pages
      const pillText = hit.category?.category ? "# " + hit.category?.category : null
      let title = hit.title || "Title missing"
      let image = getCloudinaryImage(null)
      if (hit.heroImage !== undefined && hit.heroImage !== null) image = getCloudinaryImage(hit.heroImage[0])

      if (hit.internal !== undefined) {
        let contentfuName = hit.internal.type
        contentfuName = contentfuName.replace("Contentful", "")
        contentfuName = contentfuName.replace("allContentful", "")
        let mainPath = getMainPath(contentfuName.toLowerCase())
        if (hit.public !== undefined && hit.public === false) mainPath = "/minside/eiernytt"
        url = "/nb" + mainPath + "/" + hit.slug + "/"
        // Sjekk om artikkelen er tema artikkel. da er søkeurl en annen
        if (hit.articleType === true) url = "/nb" + "/tema" + "/" + hit.slug + "/"
        if (contentfuName.toLowerCase() === "productteaser") {
          // We can get products without product groups... Return user to main page, in order to prevent crash.
          if (hit.productGroup !== undefined && hit.productGroup !== null) {
            const mainProductPath = getMainPath("productgroup")
            url = "/nb" + mainProductPath + "/" + hit.productGroup.slug + "/" + hit.slug + "/"
          } else url = "/"
        }
        if (contentfuName.toLowerCase() === "person") {
          // We only have one page for all persons
          title = hit.name
          url = "/kontakt"
        }
        if (contentfuName.toLowerCase() === "generic") {
          // We only have one page for all persons
          if (
            hit.slug === "produksjonsplanlegging" ||
            hit.slug === "vedtekter" ||
            hit.slug === "bli-eier" ||
            hit.slug === "produksjonsplanlegging-regelverk"
          )
            url = "/for-produsenter/" + hit.slug

          if (
            hit.slug === "organisasjon" ||
            hit.slug === "hvem-er-vi" ||
            hit.slug === "hva-gjor-vi" ||
            hit.slug === "var-historie" ||
            hit.slug === "hva-mener-vi"
          )
            url = "/om-gartnerhallen" + "#" + hit.slug

          if (
            hit.slug === "personvern" ||
            hit.slug === "kontakt" ||
            hit.slug === "for-produsenter" ||
            hit.slug === "aktuelt" ||
            hit.slug === "tema" ||
            hit.slug === "produktgrupper" ||
            hit.slug === "bonde" ||
            hit.slug === "om-gartnerhallen"
          )
            url = "/" + hit.slug
        }
      }
      let ingress = ""
      if (hit.metadataDescription !== undefined && hit.metadataDescription !== null) ingress = hit.metadataDescription
      else if (hit.ingress?.childMdx?.excerpt !== undefined && hit.ingress?.childMdx?.excerpt !== null)
        ingress = hit.ingress?.childMdx?.excerpt
      return {
        title: title || "Missing title",
        ingress: ingress,
        date: dt,
        category: hit.category || null,
        pillText: pillText,
        id: hit.objectID,
        image: image,
        url: url,
        heroBannerProps: heroBannerProps,
      }
    })
  if (hits.length === 0) {
    const noHitsFound: ParsedHitsType = {
      title: "Ingen treff",
      ingress: "Forsøk et annet søk",
      date: "Akkurat nå",
      id: "9808042341",
      image: getCloudinaryImage(null),
      url: "/sok",
    }
    parsedHits.push(noHitsFound)
  }
  const activeButtonStyle =
    "h-8 w-8 mx-2 hover:bg-lysgronn30-300 rounded-full bg-knapplysgronn-500 flex flex-wrap justify-center content-center"
  const inActiveButtonStyle =
    "h-8 w-8 mx-2 hover:bg-lysgronn30-300 rounded-full flex flex-wrap justify-center content-center"
  return (
    <div className="lg:px-32">
      <div className="w-full h-12 flex justify-end">
        <button className={grid ? inActiveButtonStyle : activeButtonStyle} onClick={() => setGrid(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
          </svg>
        </button>
        <button className={grid ? activeButtonStyle : inActiveButtonStyle} onClick={() => setGrid(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
            />
          </svg>
        </button>
      </div>
      {grid ? (
        <div className="justify-center flex">
          <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 my-16 gap-16 w-screen">
            {parsedHits.map((hit, i) => (
              <div key={i}>
                <Card
                  key={hit.id}
                  {...hit}
                  className=""
                  imageHight="lg:h-72 h-full"
                  ingress={hit.ingress.substring(0, 150)}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div>
          {parsedHits.map((hit) => (
            <SearchLine key={hit.id} {...hit} />
          ))}
        </div>
      )}
    </div>
  )
}
export default Hits
