import { Link } from "gatsby-plugin-react-i18next"
import { SearchCardSquareGraphics } from "../Hero/HeroImageHandlers"
import { TypographyRelatedCardDate, TypographyRelatedCardTitle } from "../Styling/Style"

/**
 * @description: Tegner resultat av søk som en linje.
 * Alt av vasking av søkeresultat skal skje i HITS klassen
 * @param props
 */

const SearchResultLine = (props) => {
  return (
    <li key={props.objectID}>
      <Link to={props.url} className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="flex flex-wrap content-center h-36 w-36">
            <SearchCardSquareGraphics {...props.heroBannerProps} />
          </div>
          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
            <div className="flex">
              <div className={TypographyRelatedCardDate + " mr-6"}>{props.date}</div>
              <div className={TypographyRelatedCardDate + " text-squash-500 font-semibold"}>
                {props.category ? <> # {props.category?.category}</> : ""}
              </div>
            </div>

            <div className="flex-1">
              <div className={TypographyRelatedCardTitle}>{props.title}</div>
            </div>
            <div>
              <div className="py-1">{props.ingress}</div>
            </div>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default SearchResultLine
