import algoliasearch from "algoliasearch/lite"
import { createRef, useState } from "react"
import { InstantSearch } from "react-instantsearch-dom"
import useClickOutside from "./use-click-outside"
import SearchBox from "./search-box"
import SearchResult from "./search-result"
import { HeroTitle96 } from "../../components/Styling/Titles"

export default function Search({ indices }) {
  const rootRef = createRef()
  const [query, setQuery] = useState()
  const [hasFocus, setFocus] = useState(false)
  const algoliaAppID = process.env.GATSBY_ALGOLIA_APP_ID || "unknown"
  const algoliSearchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY || "unknown"

  const searchClient = algoliasearch(algoliaAppID, algoliSearchKey)
  useClickOutside(rootRef, () => setFocus(false))

  // Always show results
  const [visible, setVisible] = useState(false)

  const onChangeHandler = (query) => {
    setQuery(query)
    if (query !== undefined) setVisible(true)
    if (query === undefined || query === "") setVisible(false)
    // Always show results
    // setVisible(true)
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indices[0].name}
      onSearchStateChange={({ query }) => onChangeHandler(query)}
    >
      <div className="min-w-full p-10  bg-knapplysgronn-500 h-auto">
        <span className="sr-only">Søk etter innhold</span>
        <div>
          <HeroTitle96>Søk</HeroTitle96>
          <p className="font-silksans text-center text-header48 text-squash-500 mt-14  mb-12">
            Hei! Hva kan vi hjelpe deg med?
          </p>
          <SearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} />
        </div>
      </div>
      {visible ? <SearchResult show={query && query.length > 0 && hasFocus} indices={indices} /> : <></>}
    </InstantSearch>
  )
}

// const CustomAutocomplete = connectAutoComplete(Autocomplete)
//   < CustomAutocomplete onFocus = {() => setFocus(true)} hasFocus = { hasFocus } />
