import { connectHits, Index, connectPagination, connectMenu } from "react-instantsearch-dom"
import Pagination from "./Pagination"
import Hits from "./Hits"
import Menu from "./Menu"

const CustomMenu = connectMenu(Menu)
const CustomHits = connectHits(Hits)
const CustomPagination = connectPagination(Pagination)

const HitsInIndex = ({ index }) => (
  <div>
    <Index indexName={index.name}>
      <div className="relative bg-gray-50 pt-4 lg:pb-4 lg:px-8">
        {/* <div className="flex items-center justify-center">
          <CustomMenu attribute="internal.type" />
        </div> */}
        <div className="flex items-center justify-center">
          <CustomMenu attribute="category.category" />
        </div>
      </div>
      <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          <CustomHits />
        </ul>
      </div>
      <div className="relative bg-white pt-20 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-20 lg:px-8">
        <div className="flex items-center justify-center">
          <div className="relative text-employee21 mx-auto max-w-prose lg:max-w-none">
            <CustomPagination />
          </div>
        </div>
      </div>
    </Index>
  </div>
)
const SearchResult = ({ indices, className }) => (
  <div className={className}>
    {indices.map((index) => (
      <HitsInIndex index={index} key={index.name} />
    ))}
  </div>
)
export default SearchResult
